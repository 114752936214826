<template>
<div><h1>logout</h1></div>
</template>

<script>
export default {
name: "Logout"
}
</script>

<style scoped>

</style>