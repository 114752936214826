import App from './App.vue'
import VueRouter from 'vue-router'
import router from '@/router/index'
import Vue from 'vue'
import {BootstrapVue, FormSelectPlugin, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueLogger from 'vuejs-logger';
import Keycloak from 'keycloak-js';

Vue.use(VueLogger);

let initOptions = {
  url: "https://auth.wowbpm.com/",
  realm: "backoffice",
  clientId: "whisperbot-control-service",
  onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);

keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.log(keycloak.idTokenParsed)
    Vue.$log.info("Authenticated");
    Vue.use(FormSelectPlugin)
    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    Vue.config.productionTip = false
    Vue.use(VueRouter)

    new Vue({
      el: '#app',
      router:router,
      render: h => h(App, { props: { keycloak: keycloak } })
    })


  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(1000).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token');
    });
  }, 6000)

}).catch(() => {
  Vue.$log.error("Authenticated Failed");
});
