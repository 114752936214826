<template>
  <div id="app">
    <Header :keycloak="keycloak"/>
    <router-view></router-view>
  </div>

</template>

<script>



import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Header from "@/components/Header";
export default {
  name: 'app',
  props: ['keycloak'],
  components: {
    Header
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Some Default Title';
    }
  },
  mounted() {
    console.log(this.keycloak)
  }


}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1000px;
  margin-left: auto;
  margin-right:auto;
}
li{
  cursor: pointer;
}
</style>
