<template>
  <div>
    <br>
    <p style="color: red;"><b>В тестовом режиме</b></p>

    <div class="pt-2 pb-3">
      <date-range-picker v-model="dateRange" :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }" class="col-3"
                         @update="updateValues"></date-range-picker>
    </div>

     <div>Успешных диалогов (альянс\орда): {{reports.Sent.Alliance}} \ {{reports.Sent.Horde}}</div>
      <div>Персонажей оффлайн (альянс\орда): {{reports.Offline.Alliance}} \ {{reports.Offline.Horde}}</div>

  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import axios from "axios";

export default {
  name: "ShowReports",
  components: {
    DateRangePicker
  },
  data() {
    return {
      dateRange: {
        startDate: moment().subtract(7, 'days').startOf("days").format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().endOf("days").format("YYYY-MM-DD HH:mm:ss"),
      },
      reports: {
        Sent: [],
        Offline: []
      }
    }
  },
  methods: {
    updateValues(v) {
      this.dateRange.startDate = moment(v.startDate).startOf("days").format("YYYY-MM-DD HH:mm:ss")
      this.dateRange.endDate = moment(v.endDate).endOf("days").format("YYYY-MM-DD HH:mm:ss")
      this.getReportsByOfflineAndFaction()
      this.getReportsBySentAndFaction()
    },
    async getReportsBySentAndFaction() {
      var response = await axios.get("/api/showReportsSent/" + this.dateRange.startDate + "/" + this.dateRange.endDate);
      this.reports.Sent = response.data[0]
   },
    async getReportsByOfflineAndFaction() {
      var response = await axios.get("/api/showReportsOffline/" + this.dateRange.startDate + "/" + this.dateRange.endDate);
      this.reports.Offline = response.data[0]
    }
  },
  mounted() {
    this.getReportsByOfflineAndFaction()
    this.getReportsBySentAndFaction()
  }
}
</script>

<style scoped>

</style>