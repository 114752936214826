<template>
  <div>
    <b-overlay rounded="sm" :show="loading">
      <b style="font-size:20px;">История отправок:</b><br>
      <span v-for="(v, index) in array" :key="index">
        {{v.LogType}}-{{v.CreateDate}}<br>
      </span>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PopoperMessageHistory",
  props:
      {
        UserId: Number
      },
  data(){
    return {
      array: "",
      loading:true
    }
  },
  methods:{
    async getHistorySentMessages(Id){

      const response = await axios.get("/api/showHistorySentMessages/"+Id);
      this.array = response.data;
      this.loading = false

    },
  },
  mounted(){
    this.getHistorySentMessages(this.UserId)
  }
}
</script>

<style scoped>

</style>