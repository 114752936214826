<template>
  <div class="home">

  </div>
</template>

<script>

export default {
  name: "home",
  components: {
  }
};
</script>

<style scoped>

</style>