<template>
  <div>
    <h1>login page</h1>
    <input v-model="input_login" type="text" placeholder="Your login">
    <input v-model="input_pass" type="password" placeholder="Your password">
    <button @click="checkAuth()" type="button">auth</button>
    <p v-if="error" style="color: red">Wrong input data!</p>
  </div>

</template>

<script>
export default {
name: "Login",
  data(){
    return{
        input_login:null,
        input_pass:null,
        login: "whisper",
        pass: "egorka-zloy",
        error: false
    }
  },
  methods: {
    checkAuth(){
      if(this.input_login == this.login && this.input_pass == this.pass){
        window.localStorage.setItem("auth", true);
        location.reload();
        this.$router.push("/home");
      } else {
        this.error = true;
      }
    }
  }
}
</script>

<style scoped>

</style>