<template>
  <div>
    <div class="row mt-5">
      <date-range-picker @update="tableUpdate" class="col-3" v-model="dateRange" :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"></date-range-picker>
      <b-form-select  @change="getCharacters" v-model="to.dateSql.limitSql" :options="LimitOptions" class="col-1"></b-form-select>
      <div class="col-4 pt-2" v-if="charactersArray.length">
        Всего найдено {{charactersArray.length}} персонажей
      </div>
    </div>
    <b-overlay :show="show" rounded="sm">
    <CharactersTable :data="charactersArray" />
    </b-overlay>
  </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
var moment = require('moment');
import axios from "axios";
import CharactersTable from "@/components/CharactersTable/CharactersTable";
moment().utcOffset(8);

export default {
name: "ShowCharacters",
  data(){
  return{
    show: false,
    LimitOptions:[
      {value: 10, text:10},
      {value: 50, text:50},
      {value: 100, text:100},
      {value: 150, text:150},
      {value: 200, text:200},
      {value: 300, text:300},
      {value: 400, text:400},
      {value: 500, text:500},
      {value: 1000, text:1000},
      {value: 2000, text:2000},
    ],
    dateRange: {
      startDate: moment().subtract(7,'days'),
      endDate:moment(),
    },
    test(e){
      console.log(e)
    },
    to:{
      dateSql:{
        startDate:null,
        endDate:null,
        limitSql:10
      },
      key:null,
    },
    charactersArray: [
    ],
  }
  },
  methods: {
    popoverData(item){
       this.getHistorySentMessages(item.Id)
      return {
        title: item.NickName+"-"+item.Realm,
        content:  function () {
          return "1"
        }
      }
    },
    tableUpdate(){

      this.to.dateSql.startDate = moment(this.dateRange.startDate).startOf("days").format("YYYY-MM-DD HH:mm:ss");
      this.to.dateSql.endDate = moment(this.dateRange.endDate).endOf("days").format("YYYY-MM-DD HH:mm:ss");
      this.getCharacters()

    },
    async getCharacters() {
      this.show=true;
      var dataObj = this.to.dateSql;
      const response = await axios.get("/api/showCharacters/"+dataObj.startDate+"/"+dataObj.endDate+"/"+dataObj.limitSql+"/");
      this.charactersArray = response.data;
      this.show=false;
    }

  },
  components:{
    CharactersTable,
    DateRangePicker
  },
  mounted(){

    this.tableUpdate()



  }
}
</script>

<style scoped>

</style>