<template>
  <div>



    <div class="mt-5">
      <b-table striped hover :fields="fields" :items="messagesArray">

        <template #cell(Actions)="row">
          <b-icon style="cursor:pointer" icon="pencil" @click="editMsg(row)" scale="1" ></b-icon>
        </template>

      </b-table>
    </div>



    <div class="mt-3 edit-block" v-if="editBlock" >
      <div class="row">

        <div class="col-3">
          <b-form-select class="form-control" placeholder="Portal" v-model="editItem.Portal" :options="portalOptions"></b-form-select>
        </div>

        <div class="col-3" v-if="editItem.Portal=='EU'">
          <b-form-select class="form-control" placeholder="Locale" v-model="editItem.Locale" :options="localeOptions"></b-form-select>
        </div>

        </div>
<br>
      <div class="row" v-if="editItem.Portal != null">
        <div class="col-3">
          <b-form-select class="form-control" placeholder="Channel" v-model="editItem.Channel" :options="channelOptions"></b-form-select>
        </div>

        <div class="col-3">
          <b-form-input class="form-control" placeholder="Order" v-model="editItem.Order" value="0" type="number"></b-form-input>
        </div>
      </div>
      <br>
      <div class="row" v-if="editItem.Portal != null">
        <div class="col-3">
          <b-form-select class="form-control" placeholder="ProductType" v-model="editItem.Product" :options="productOptions"></b-form-select>
        </div>
      </div>

      <div class="col-6 mt-3">
        <b-form-textarea placeholder="Message text..." v-model="editItem.MessageText"></b-form-textarea>
      </div>


      <div class="row mt-3">
        <div class="col-3">
          <button v-if="!editMode" @click="createMessage()" class="btn btn-primary">Добавить</button>
         <button v-else class="btn btn-primary" @click="updateMessage()">Сохранить</button>
        </div>
        <div class="col-3">
          <button class="btn btn-secondary" @click="closeBlock">Закрыть</button>
        </div>
      </div>
    </div>
    <button @click="openAdd()" v-if="!editBlock" class="btn btn-primary">Добавить</button>

  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "ShowMessages",
  data() {
    return {
      fields: [
        {key:'Portal',label:'Portal'},
        {key:'ProductType',label:'ProductType'},
        {key:'Locale',label:'Locale'},
        {key:'Channel', label:'Channel'},
        {key:'MessageText', label:'Message text'},
        {key:'Order', label:'Order'},
        {key:'CreateDate', label:'Create date'},
        {key:'Actions', label:'Actions'}
      ],
      messagesArray: [],
      channel: null,
      channelOptions: [
        {value: null, text:"-- Please select channel --"},
        {value: "DND", text: "DND"},
        {value: "Whisper", text: "Whisper"},
      ],
      productOptions: [
        {value: null, text:"-- Please select product --"},
        {value: "WowRetail", text: "WowRetail"},
        {value: "WowClassic", text: "WowClassic"}
      ],
      portalOptions:[
        {value: null, text:"-- Please select portal --"},
        {value: "EU", text:"Europe"},
        {value: "US", text:"USA & Oceania"}
      ],
      localeOptions:[
        {value: null, text:"-- Please select locale --"},
        {value:"enUS", text:"enUS"},
        {value:"euEU", text:"ruRU"}
      ],
      message: null,
      order: null,
      editItem: {
        Channel:null,
        MessageText:null,
        Order:null,
        Portal:null,
        Locale:null,
        Product:null
      },
      editMode:false,
      editBlock:false

    }
  },
  methods: {
    openAdd(){
      this.editItem.Channel=null
      this.editItem.MessageText=null
      this.editItem.Order=null
      this.getMessages()
      this.editBlock=true
      this.editItem.Product = null
    },
    closeBlock() {
      this.editBlock = false;
      this.editMode =false
    },
    async getMessages() {

      const response = await axios.get("/api/showMessages");
      this.messagesArray = response.data;


    },
    async updateMessage() {
      try {
        var a = this;
        await axios.put("/api/updateMessage/" + a.editItem.Id, {
          data: a.editItem
        }).then(function (response) {
          if (response.status == 200) {
            a.getMessages()
            a.closeBlock()


          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async createMessage() {
      try {
        var a = this;
       // console.log(a.editItem)
        await axios.post("/api/createMessage", {
          data: this.editItem
        }).then(function (response) {
          if (response.status == 200) {
            a.getMessages()
            a.closeBlock()
          }

        });

      } catch (e) {
        console.log(e);
      }

    },
    editMsg(item) {
      this.editMode = true;
      this.editBlock = true;
      this.editItem = item.item;

    },
    deleteMsg() {
      alert("В разработке")
    }

  },
  mounted() {
    this.getMessages()
  }
}
</script>

<style scoped>
table, td, tr {
  border: 1px solid
}

.edit-block {
  border: 1px solid #2c3e50;
  padding: 5px
}
</style>
