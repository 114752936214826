<template>
<div>
  <div class="mb-5"></div>
  <b-overlay :show="loading" rounded="sm">
  <CharactersTable :data="charactersArray" />
  </b-overlay>
</div>
</template>

<script>
import CharactersTable from "@/components/CharactersTable/CharactersTable";
import axios from "axios";
export default {
name: "search",
  components: {CharactersTable},
  data() {
    return{
      queryString:null,
      items:null,
      charactersArray:null,
      loading:false,
    }
  },
  methods:{
    async query(){
      this.loading=true;
      const response = await axios.get("/api/showSearchResults/"+this.queryString);
      this.charactersArray = response.data;
      this.loading=false;
    }
  },
  watch:{
    $route(o){
      this.queryString = o.params.query;
      this.query()
    }
  },
  mounted(){
    this.queryString = this.$route.params.query;
    this.query()
  }
}
</script>

<style scoped>

</style>