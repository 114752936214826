<template>
  <div>
    <br>

    <div>
      <b-overlay :show="overlay" rounded="sm">
      <b-tabs v-if="!response" content-class="mt-3">
        <b-tab title="Форма 1" active>
          <varian-two @saveCharacters="saveCharacters"/>
        </b-tab>
<!--        <b-tab title="Форма 2" >
          <variant-one @saveCharacters="saveCharacters"  />
        </b-tab>-->

      </b-tabs>
        <div v-else>
          <h5 style="color: green">Результаты:</h5>
          <b>Добавлено персонажей:</b>{{response.data.affectedRows}}
          <b><br>Найдено дубликатов:</b>{{response.data.warningStatus}}
        </div>
      </b-overlay>
<br>
      <button class="btn btn-primary" @click="response = false" v-if="response">Есть желание добавить еще персонажей!</button>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import VarianTwo from "@/components/addCharacters/varianTwo";

export default {
  name: "AddCharacters",
  data() {
    return {
      progressSend:false,
      overlay:false,
      response:false
    }
  },
  methods: {
    async saveCharacters(data){
      this.overlay = true;
      console.log(data);
      var self = this;
     try {
        await axios.post("/api/createCharacters",{
          data: data
          }).then(function(response) {

            console.log(response)

          if(response.status == 200){
            console.log(response.data.info)
            self.response = response
            self.overlay = false
          }

          if(response.status == 500){
            alert(response.data.info)
            self.response = response
            self.overlay = false
          }


          });

      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted(){

  },
  components:{
    VarianTwo
  }

}
</script>

<style scoped>
.sel{
  width:200px;
  height:38px
}
</style>