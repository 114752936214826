<template>
<div>

  <b-navbar toggleable="sm" type="light" variant="light">
    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

    <b-navbar-brand class="m-lg-2">WB</b-navbar-brand>

    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-text>
          <router-link tag="li" to="/AddCharacters">Добавить персонажа</router-link>
        </b-nav-text>
        <b-nav-text>
          <router-link tag="li" to="/ShowCharacters">Все персонажи</router-link>
        </b-nav-text>
        <b-nav-text>
          <router-link tag="li" to="/ShowMessages">Все сообщения</router-link>
        </b-nav-text>

        <b-nav-form>
          <b-form-input size="xl" v-model="searchQuery" class="mr-sm-2" placeholder="Поиск..."></b-form-input>
        </b-nav-form>
        <b-nav-text>
          <span @click="keycloak.logout()">{{keycloak.idTokenParsed.name}} Выйти</span>
        </b-nav-text>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
export default {
  name: "Header",
  props:["keycloak"],
  data() {
    return {
      searchQuery: null
    }
  },
  watch: {
    searchQuery(val){
      // clear timeout variable
      clearTimeout(this.timeout);
      var self = this;
      this.timeout = setTimeout(function () {
        if(val.length > 3)
        {
          self.$router.push(`/Search/${val}` );
        }
        self.outputValue = self.value;
      }, 1000);



    }
  }
}

</script>

<style scoped>
li{
  margin-left: 5px;
  margin-right: 5px;
}
</style>
