<template>
  <div>
    <b-table striped hover :fields="fields" :items="data">

      <template #cell(Status)="row">

        <span :id="'popover-'+row.item.Id" variant="primary">
          <span style="cursor:pointer;">{{ (row.item.Delivery) ? row.item.Delivery : 'Processing' }} <b-icon
              icon="chat-left-text" aria-label="Help"></b-icon></span>
        </span>

        <b-popover :target="'popover-'+row.item.Id" triggers="hover focus">
          <template #title>{{ row.item.NickName }}-{{ row.item.Realm }}</template>
          <PopoperMessageHistory :UserId="row.item.Id"/>
        </b-popover>

      </template>

    </b-table>
  </div>
</template>

<script>
import PopoperMessageHistory from "@/components/CharactersTable/PopoperMessageHistory";
export default {
  name: "CharactersTable",
  components: {PopoperMessageHistory},
  props: {
    data: Array
  },
  data() {
    return {
      fields: [
        {
          key:'Portal',
          label:'Portal',
          sortable: true
        },
        {
          key:'ProductType',
          label:'ProductType',
          sortable: true
        },
        {
          key:'Locale',
          label:'Locale',
          sortable: true
        },
        {
          key: 'NickName',
          label: 'Ник Персонажа',
          sortable: true

        },
        {
          key: 'Realm',
          label: 'Cервер'
        },
        {
          key: 'Faction',
          label: 'Фракция',
          sortable: true
        },
        {
          key: 'CreateDate',
          label: 'Дата добавления',
          sortable: true
        },
        {
          key: 'Status',
          label: 'Статус',
          sortable: true, sortDirection: 'desc'
        }

      ]
    }
  }
}
</script>

<style scoped>

</style>