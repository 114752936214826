import VueRouter from "vue-router";
import Home from "@/views/Home";
import AddCharacters from "@/views/AddCharacters";
import ShowCharacters from "@/views/ShowCharacters";
import ShowMessages from "@/views/ShowMessages";
import ShowReports from "@/views/ShowReports";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import Search from "@/views/Search";






const routes = [

    {
        path:'/',
        component: Home,
        meta:{
            title:"Admin BotWhisper"
        }
    },
    {
        path:'/home',
        component: Home,
        meta:{
            title:"Admin BotWhisper"
        }
    },
    {
        path:'/Login',
        component: Login
    },
    {
        path:'/Logout',
        component: Logout
    },
    {
        path:'/AddCharacters',
        component: AddCharacters,
        meta:{
            title:"Добавление персонажей"
        }
    },
    {
        path:'/ShowCharacters',
        component: ShowCharacters,
        meta:{
            title:"Все персонажей"
        }
    },
    {
        path:'/ShowMessages',
        component: ShowMessages,
        meta:{
            title:"Все сообщения"
        }
    },
    {
        path:'/ShowReports',
        component: ShowReports,
        meta:{
            title:"Отчеты"
        }
    },
    {
        path:'/Search/:query',
        component: Search,
        meta:{
            title:"Результат поиска"
        }
    }

]

const router = new VueRouter({
    routes: routes
})



export default router