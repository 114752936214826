<template>
  <div>

    <b-modal id="modal-lg" size="lg" ref="my-modal" hide-footer title="У вас есть ошибки">
      <div class="d-block text-center">
        <p style="color: red">Исправьте ошибки перед тем как продолжить.</p>
        <b-table :fields="fields" :items="checkedArrayError" hover striped>
          <template #cell(Portal)>
            {{ selectPortal }}
          </template>
          <template #cell(NickName)="row">
            <b-form-input v-model="checkedArrayError[row.index].NickName"
                          :disabled="!loading ? false : true"></b-form-input>
          </template>
          <template #cell(Realm)="row">
            <b-form-input v-model="checkedArrayError[row.index].Realm"
                          :disabled="!loading ? false : true"></b-form-input>
          </template>
          <template #cell(Locale)>
            {{ selectLocale }}
          </template>
          <template v-if="!loading" #cell(Action)="row">
            <b-icon icon="x-circle" scale="1" style="cursor:pointer" variant="danger"
                    @click="delCharacter(row.index)" :disabled="!loading ? false : true"></b-icon>
          </template>
        </b-table>

        <b-button :disabled="!loading ? false : true" class="me-3" variant="primary" @click="validation">
          <b-spinner v-if="loading" small></b-spinner>
          <span v-if="loading" class="sr-only">Проверка...</span>
          <span v-if="!loading">Продолжить</span>
        </b-button>

        <b-button
            style="margin-left: 10px;"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible = !visible"
        >
          {{ this.visibleStatus }} Список серверов
        </b-button>
        <b-collapse id="collapse-4" v-model="visible" class="mt-2">
          <p v-for="(item, index) in realms" :key="index">{{ item.slug }}</p>
        </b-collapse>


      </div>

    </b-modal>

    <b-row>
      <b-col sm="2">
        <label for="textarea-auto-height">Characters:</label>
      </b-col>
      <b-col sm="10">
        <b-form-textarea
            id="textarea-auto-height"
            v-model="textarea"
            max-rows="8"
            placeholder="NickName-Realm"
            rows="3"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col sm="2">
        <label>Portal:</label>
      </b-col>
      <b-col>
        <div>
          <b-form-select v-model="selectPortal" :options="portals"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row v-if="selectPortal != null">
      <b-col sm="2">
        <label>WowProduct:</label>
      </b-col>
      <b-col>
        <div>
          <b-form-select v-model="selectProduct" :options="products"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <br>


    <b-row v-if="selectProduct != null">
      <b-col sm="2">
        <label>Faction:</label>
      </b-col>
      <b-col>
        <div>
          <b-form-select v-model="selectFaction" :options="options"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <br>

    <b-row v-if="selectPortal=='EU' && selectFaction != null">
      <b-col sm="2">
        <label>Locale:</label>
      </b-col>
      <b-col>
        <div>
          <b-form-select v-model="selectLocale" :options="locales"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <br>

    <span style="color: red">{{ errorMessage }}</span><br>

    <b-button v-if="selectLocale != null" :disabled="!loading ? false : true" class="me-3" variant="primary" @click="checkTextarea">
      <b-spinner v-if="loading" small></b-spinner>
      <span v-if="loading" class="sr-only">Checked..</span>
      <span v-if="!loading">Сохранить</span>
    </b-button>


  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "varianTwo",
  data() {
    return {
      visible: false,
      visibleStatus: 'Показать',
      fields: [
        {key: "Portal", label: "Portal"},
        {key: "ProductType", label: "ProductType"},
        {key: "Locale", label: "Locale"},
        {key: "NickName", label: "NickName"},
        {key: "Realm", label: "Realm"},
        {key: "Faction", label: "Faction"},
        {key: "Action", label: "Action"}
      ],
      textarea: null,
      errorMessage: '',
      error: false,
      successMessage: '',
      loading: false,
      checkedArraySuccess: [],
      checkedArrayError: [],
      selectFaction: null,
      selectLocale: null,
      selectPortal: null,
      selectProduct: null,
      options: [
        {value: null, text: '-- Please select an faction --'},
        {value: "Alliance", text: "Alliance"},
        {value: "Horde", text: "Horde"}
      ],
      locales: [
        {value: null, text: '-- Please select locale --'},
        {value: "enUS", text: "EN"},
        {value: "ruRU", text: "RU"}
      ],
      products: [
        {value: null, text: '-- Please select locale --'},
        {value: "WowRetail", text: "WowRetail"},
        {value: "WowClassic", text: "WowClassic"}
      ],
      portals: [
        {value: null, text: '-- Please select game portal --'},
        {value: "EU", text: "Europe"},
        {value: "US", text: "USA & Oceania"}
      ],
      realms: [],
      realmsRU: [
        {name: "азурегос", slug: "азурегос"},
        {name: "борейскаятундра", slug: "борейскаятундра"},
        {name: "вечнаяпесня", slug: "вечнаяпесня"},
        {name: "галакронд", slug: "галакронд"},
        {name: "гордунни", slug: "гордунни"},
        {name: "гром", slug: "гром"},
        {name: "дракономор", slug: "дракономор"},
        {name: "корольлич", slug: "корольлич"},
        {name: "пиратскаябухта", slug: "пиратскаябухта"},
        {name: "подземье", slug: "подземье"},
        {name: "разувий", slug: "разувий"},
        {name: "ревущийфьорд", slug: "ревущийфьорд"},
        {name: "свежевательдуш", slug: "свежевательдуш"},
        {name: "седогрив", slug: "ревущийфьорд"},
        {name: "стражсмерти", slug: "стражсмерти"},
        {name: "термоштепсель", slug: "термоштепсель"},
        {name: "ткачсмерти", slug: "ткачсмерти"},
        {name: "черныйшрам", slug: "черныйшрам"},
        {name: "ясеневыйлес", slug: "ясеневыйлес"},
          {name:"Пламегор", slug:"пламегор"}
      ]
    }
  },
  watch: {
    visible(val) {
      if (val == true) {
        this.visibleStatus = "Закрыть";
      } else {
        this.visibleStatus = "Показать";
      }
    },
    selectPortal(val) {
      if (val == "US") {
        this.selectLocale = "enUS"
      }
      if (val == "EU") {
        this.selectLocale = null;
      }
      if (val == null) {
        this.realms = [];
      }
      this.getRealmsByPortal(val);
    },
    selectLocale(val) {
      if (val == "ruRU") {
        this.realms = this.realmsRU;
      }
      if (val == "enUS" && this.selectPortal == "EU") {
        this.getRealmsByPortal("EU");
      }
    }
  },
  methods: {
    async getRealmsByPortal(portal) {

      var realms1 = [];
      if (portal == null) return false;
      try {
        await axios.get("/api/getRealms/" + portal).then(function (response) {

          response.data.forEach(function (item) {

            realms1.push({
              name: item.name,
              slug: item.slug
            })

          })
        })

        this.realms = realms1;
        console.log(realms1)
      } catch (e) {
        console.log(e)
      }
    },
    sendToSaveCharacters(newArray) {
      this.$emit('saveCharacters', newArray);
      this.checkedArrayError = []
      this.checkedArraySuccess = []
      this.$refs['my-modal'].hide()
    },
    validation() {
      var newArray = this.checkedArraySuccess.concat(this.checkedArrayError);
      this.sendToSaveCharacters(newArray)
    },
    delCharacter(index) {
      this.checkedArrayError.splice(index, 1);
    },
    checkTextarea() {
      var self = this;

      self.errorMessage = "";
      this.loading = true;

      if (this.textarea == null) {
        self.errorMessage = "Для продолжения введите минимум 1 строку";
        self.loading = false;
        return false;
      } else {
        var spl1 = this.textarea.split("\n")
      }

      if (spl1.length > 5000) {
        self.errorMessage = "Не более 5000 строк можно добавить за 1 раз";
        self.loading = false;
        return false;
      }

      if (self.selectPortal == null) {
        self.errorMessage = "Необходимо выбрать Portal";
        self.loading = false;
        return false;
      }

      if (self.selectFaction == null) {
        self.errorMessage = "Необходимо выбрать фракцию персонажей";
        self.loading = false;
        return false;
      }

      if (self.selectLocale == null) {
        self.errorMessage = "Необходимо выбрать локализацию";
        self.loading = false;
        return false;
      }

      this.checkedArrayError = []
      this.checkedArraySuccess = []
      var realms = self.realms;
      spl1.forEach(function (value) {

        for (let item of realms) {
          console.log(item.name)
          var iof = value.toLowerCase().indexOf(item.name.toLowerCase())
          if (iof !== -1) {
            self.checkedArraySuccess.push(
                {
                  NickName: value.substring(0, iof),
                  Realm: item.slug,
                  ProductType: self.selectProduct,
                  Faction: self.selectFaction,
                  Portal: self.selectPortal,
                  Locale: self.selectLocale
                }
            )

          }

        }
      });

     /* self.error = true;
      console.log(newAw)
      if (self.error) {
        self.$refs['my-modal'].show()
        self.loading = false;
      }*/


      if (!this.error) {
        this.sendToSaveCharacters(self.checkedArraySuccess)
      }

    }
  }
}
</script>

<style scoped>
#collapse-4 p {
  margin-left: 5px;
  margin-right: 5px;
  float: left;
  border: 1px dashed #606060;
  padding-left: 3px;
  padding-right: 3px;
  background: #ededed;
}
</style>